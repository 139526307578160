<template>
    <div id="dialog-template"></div>
</template>
<script>

  export default {
    name: 'alert',
    props: ['data'],
    template: '#dialog-template',
    methods:{
      showAlert(title,body){
        this.$modal.show('dialog', {
          title: title,
          text: body,
          buttons: [
            {
              title: 'Tutup',
              handler: () => {
                this.$modal.hide('dialog')
              }
            }, 
          ]
        })
      }
    },
  };
</script>